<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.confirmBookingFee.title') }}
      </div>
      <SectionLoader :show="loading" />
      <section class="modal--container accept--survey-container">
        <div class="desc" v-if="$mq !== 'xs'">
          {{ $t('booking.modal.confirmBookingFee.title') }}
        </div>
        <div class="date--time">
          {{
            isApplicant
              ? this.$i18n.t('booking.modal.confirmBookingFee.applicant')
              : this.$i18n.t('booking.modal.confirmBookingFee.owner')
          }}
        </div>
        <hr class="separator" />
        <div class="d-flex justify-space-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="close">
            {{ $t('general.btn.back') }}
          </div>
          <div class="btn btn-primary--outline main--btn" @click="reject">
            {{ buttonName }}
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
const SectionLoader = () => import('@/components/content-loading/section-loading');
import { mapState } from 'vuex';

export default {
  name: 'sell-confirm-booking-fee-modal',
  components: { SectionLoader },
  computed: {
    ...mapState({
      loginUser: (state) => state.global.user,
    }),
    isApplicant() {
      let isApplicant = false;
      if (this.bookingInfo) {
        isApplicant = this.loginUser.id == this.bookingInfo.applicant_id;
      }
      return isApplicant;
    },
  },
  data() {
    return {
      modalName: 'sell-confirm-booking-fee',
      loading: false,
      buttonName: '',
      uuid: null,
      bookingInfo: null,
    };
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      this.uuid = event.params.uuid;
      this.buttonName = event.params.buttonName;
      this.bookingInfo = event.params.bookingInfo;
    },
    async reject() {
      this.loading = true;
      try {
        let data = await this.$store.dispatch('sellTransaction/confirmBookingFee', this.uuid);
        if (data) {
          this.$emit('actionDone');
          this.close();
          this.$swal(
            this.$t('general.success'),
            this.$t('booking.swal.sellTransaction.confirmBookingFee'),
            'success',
          );
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
